body {
  display: flex;
  flex-direction: column; }

article {
  flex-grow: 1; }

body > header, body > footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

body > header, body > article, body > footer {
  padding: 1.5em; }

article header {
  margin-bottom: 1.5em; }

article header h1 {
  font-size: 1.7em;
  margin: 0 0 .1em; }

nav {
  margin: .5em -1.2em; }

nav a {
  margin: .5em 1.2em; }
